import React from 'react';
import firebase from 'firebase';
export class Leaderboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			groups: []
		};
	}

	componentDidMount() {
		firebase.firestore().collection('groups').orderBy('points', 'desc').onSnapshot((data) => {
			this.setState((oldState) => {
				const groups = [];
				data.forEach((question) => {
					const { icon, name, points } = question.data();
					const id = question.id;
					groups.push({
						icon,
						name,
						id,
						points
					});
				});
				return {
					groups
				};
			});
		});
	}

	render() {
		const projectList = (this.state.groups || []).map((group, index) => (
			<div key={index} className="lb-group" style={{ display: 'flex', flexDirection: 'column' }}>
				<span style={{ fontWeight: 100 }}>{index + 1}</span>
				{group.name}
				<i>{group.points}</i>
			</div>
		));
		return <div style={{ marginRight: 10, display: 'flex' }}>{projectList}</div>;
	}
}
