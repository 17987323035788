import React from 'react';
import firebase from 'firebase';

export class Configure extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.id,
			backgroundImage: '',
			title: '',
			type: 'text'
		};

		this.loadID = this.loadID.bind(this);
		if (props.id) {
			this.loadID(props.id);
		}
		this.clearID = this.clearID.bind(this);
		this.setTitle = this.setTitle.bind(this);
		this.setType = this.setType.bind(this);
		this.submit = this.submit.bind(this);
		this.setBackgroundImage = this.setBackgroundImage.bind(this);
	}

	componentWillReceiveProps(newProps) {
		if (newProps.id) {
			this.setState({ id: newProps.id });
			this.loadID(newProps.id);
		}
	}

	loadID(id) {
		firebase.firestore().collection('questions').doc(id).onSnapshot((question) => {
			if (question.exists) {
				const { title, type, backgroundImage } = question.data();
				this.setState({
					title,
					type,
					backgroundImage: backgroundImage || ''
				});
			}
		});
	}
	setTitle(e) {
		this.setState({
			title: e.target.value
		});
	}

	setBackgroundImage(e) {
		this.setState({
			backgroundImage: e.target.value
		});
	}

	setType(e) {
		this.setState({
			type: e.target.value
		});
	}

	submit() {
		let backgroundImageOptions = {};

		if (this.state.type === 'draw') {
			backgroundImageOptions = {
				backgroundImage: this.state.backgroundImage
			};
		}

		if (this.state.id) {
			firebase
				.firestore()
				.collection('questions')
				.doc(this.state.id)
				.update({
					title: this.state.title,
					type: this.state.type,
					...backgroundImageOptions
				})
				.then(() => {
					this.clearID();
				});
		} else {
			const timestamp = firebase.firestore.FieldValue.serverTimestamp;

			firebase
				.firestore()
				.collection('questions')
				.add({
					title: this.state.title,
					type: this.state.type,
					createdAt: timestamp(),
					...backgroundImageOptions
				})
				.then(() => {
					this.clearID();
				});
		}
	}

	clearID() {
		this.setState({
			id: null,
			type: 'text',
			title: ''
		});
	}
	render() {
		return (
			<div className="question-inner" style={{ padding: 10 }}>
				<h4 class="question-inner-title">{this.state.id ? 'Edit Question' : 'Create New Question'}</h4>
				<div className="form-group">
					<label for="exampleInputEmail1">Question Title</label>
					<input
						type="text"
						onChange={this.setTitle}
						value={this.state.title}
						className="form-control"
						id="exampleInputEmail1"
						aria-describedby="emailHelp"
					/>
				</div>
				<div className="form-group">

				<label for="qType">Question Type</label>

				<select id="qType" className="form-control" onChange={this.setType} value={this.state.type}>
					<option value="text">Text</option>
					<option value="draw">Draw</option>
				</select>
				</div>

				{this.state.type === 'draw' ? (
					<div className="form-group">
						<label for="exampleInputURL1">Background Image</label>
						<input
							type="url"
							onChange={this.setBackgroundImage}
							value={this.state.backgroundImage}
							className="form-control"
							id="exampleInputURL1"
						/>
					</div>
				) : (
					''
				)}

				<div class="btn-group" style={{ width: '100%' }}>
					<button
						type="button"
						disabled={this.state.title === '' || this.state.type === ''}
						className="btn btn-primary  save-question-button"
						onClick={this.submit}
					>
						Submit
					</button>

					{this.state.id ? (
						<button
							type="button"
							onClick={this.clearID}
							className="btn btn-outline-danger  save-question-button"
						>
							Cancel
						</button>
					) : (
						''
					)}
				</div>
			</div>
		);
	}
}
