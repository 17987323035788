import React from 'react';
import firebase from 'firebase';
export class Questions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			questions: []
		};
	}

	componentDidMount() {
		firebase.firestore().collection('questions').orderBy('createdAt').onSnapshot((data) => {
			this.setState((oldState) => {
				const questions = [];
				data.forEach((question) => {
					console.log(question.data());
					const { title, type, visible } = question.data();
					const id = question.id;
					questions.push({
						title,
						type,
						id,
						visible
					});
				});
				return {
					questions
				};
			});
		});
	}

	selectQuestion(id) {
		if (this.props.onSelectQuestion) {
			this.props.onSelectQuestion(id);
		}
	}

	makeQuestionActive(id) {
		firebase
			.firestore()
			.collection('questions')
			.where('visible', '==', true)
			.get()
			.then(async (value) => {
				return await value.forEach((visibleq) => {
					firebase.firestore().collection('questions').doc(visibleq.id).update({ visible: false });
				});
			})
			.then(() => {
				firebase.firestore().collection('questions').doc(id).update({
					visible: true
				});
			});
	}

	clearSelected() {
		firebase.firestore().collection('questions').where('visible', '==', true).get().then(async (value) => {
			return await value.forEach((visibleq) => {
				firebase.firestore().collection('questions').doc(visibleq.id).update({ visible: false });
			});
		});
	}
	render() {
		const projectList = (this.state.questions || []).map((question, index) => (
			<div
				className="question-item"
				key={index}
				onDoubleClick={this.makeQuestionActive.bind(this, question.id)}
				onClick={this.selectQuestion.bind(this, question.id)}
			>
				<span className="question-item-index">{index + 1}</span>
				{question.title}
				{question.visible ? (
					<svg
						className="bi bi-display-fill"
						width="1em"
						height="1em"
						viewBox="0 0 16 16"
						style={{float: "right"}}
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M5.75 13.5c.167-.333.25-.833.25-1.5h4c0 .667.083 1.167.25 1.5H11a.5.5 0 010 1H5a.5.5 0 010-1h.75z" />
						<path
							fillRule="evenodd"
							d="M13.991 3H2c-.325 0-.502.078-.602.145a.758.758 0 00-.254.302A1.46 1.46 0 001 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 00.538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 00.254-.302 1.464 1.464 0 00.143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 00-.302-.254A1.46 1.46 0 0013.99 3zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z"
							clipRule="evenodd"
						/>
						<path d="M2 4h12v6H2z" />
					</svg>
				) : (
					''
				)}
			</div>
		));
		return (
			<div style={{ marginRight: 10 }}>
				<button
					style={{ margin: 10 }}
					class="btn btn-outline-primary btn-block"
					onClick={this.clearSelected.bind(this)}
				>
					Pause
				</button>

				{projectList}
			</div>
		);
	}
}
