
import firebase from 'firebase'
import React from 'react'


export class Icon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: '',
			icon: props.icon
		}
	}

	componentWillReceiveProps(n, p) {
		if(n.icon !== p.icon) {
			this.setState({
				icon: n.icon
			})
		}
	}

	componentDidMount() {
		this.getIcon(this.state.icon).then((url) => {
			this.setState({url})
		});
	}

	async getIcon(icon) {
		console.log(icon)
		const url = await firebase.storage().refFromURL('gs://zooquiz-5c5e4.appspot.com/' + icon + '.svg').getDownloadURL()
		return url
	}

	render() {
		if (this.state.url !== '') {
			return <img className={`icon-button`}  src={this.state.url} alt="" />;
		} else {
			return "";
		}
	}

}