import React from 'react';
import firebase from 'firebase';
import { Canvas } from './canvas';
import { Icon } from '../core';

export class ViewResults extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			questionType: '',
			id: props.id,
			groups: [],

			answers: []
		};
		this.loadQuestions = this.loadQuestions.bind(this);
	}

	componentWillMount() {}
	componentDidMount() {
		this.loadQuestions(this.state.id);
		firebase.firestore().collection('groups').onSnapshot((groups) => {
			const outputGroups = {};
			groups.docs.forEach((group) => {
				outputGroups[group.id] = {
					...group.data()
				};
			});
			this.setState({
				groups: outputGroups
			});
		});
	}

	componentWillReceiveProps(next, prev) {
		this.setState({
			id: next.id
		});
		this.loadQuestions(next.id);
	}

	addPoints(groupID, incr) {
		const increment = firebase.firestore.FieldValue.increment(incr);
		firebase.firestore().collection('groups').doc(groupID).update({
			points: increment
		});
	}

	loadQuestions(id) {
		this.setState({
			questionType: ''
		});

		firebase.firestore().collection('questions').doc(id).onSnapshot((question) => {
			const { type, backgroundImage } = question.data();
			this.setState({
				questionType: type,
				backgroundImage: backgroundImage || null
			});
		});
		firebase
			.firestore()
			.collection('questions')
			.doc(id)
			.collection('answers')
			.where('submitted', '==', true)
			.onSnapshot((data) => {
				this.setState((oldState) => {
					const answers = [];
					data.forEach((question) => {
						const { lines, submitted, value } = question.data();
						const id = question.id;
						answers.push({
							lines,
							submitted,
							value,
							id
						});
					});
					return {
						answers
					};
				});
			});
	}
	render() {
		let answers = [];

		answers = this.state.answers.map((answer) => {
			let name = '';
			let icon = '';
			let points = '';

			if (this.state.groups && this.state.groups[answer.id] && this.state.groups[answer.id].name) {
				name = this.state.groups[answer.id].name;
				icon = this.state.groups[answer.id].icon ? <Icon icon={this.state.groups[answer.id].icon} /> : '';
				points = this.state.groups[answer.id].points;
			}
			return (
				<span className="answer-wrap">
					<h4 style={{ display: 'flex' }}>
						{icon} {name} <span style={{ flexGrow: 1 }}>&nbsp;</span>{' '}
						<span style={{ color: ' #8c8c8c' }}>{points} points</span>
					</h4>
					{this.state.questionType === 'draw' ? (
						<Canvas lines={answer.lines} backgroundImage={this.state.backgroundImage} />
					) : (
						<div class="qanswer">{answer.value}</div>
					)}

					<div style={{ display: 'flex' }}>
						<button
							class="btn change-points-btn btn-outline-danger"
							onClick={this.addPoints.bind(this, answer.id, -5)}
						>
							-5
						</button>

						<button
							class="btn  change-points-btn btn-outline-success"
							onClick={this.addPoints.bind(this, answer.id, 5)}
						>
							+5
						</button>
						<button
							class="btn  change-points-btn btn-outline-success"
							onClick={this.addPoints.bind(this, answer.id, 10)}
						>
							+10
						</button>
					</div>
				</span>
			);
		});

		return answers;
	}
}
