/* eslint-disable no-undef */
import React from 'react';

export class Canvas extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
		this.state = {
			lines: props.lines,
			backgroundImage: props.backgroundImage
		}
	}

	componentWillReceiveProps(n, p) {
		if(n.lines !== p.lines) {
			this.setState({
				lines: n.lines,
				backgroundImage: n.backgroundImage
			})
		}
	}
	componentWillMount() {
		
	}

	Sketch = (p) => {
		let x = 500;
		let y = 500;

		p.setup = () => {
			p.createCanvas(x, y);
		
		};

		p.draw = () => {
			p.strokeWeight(5);
			(this.state.lines || []).filter((value) => !value.marked).forEach((value, index) => {
				value.marked = true;
				p.stroke(value.color)
				return index === 0 || this.state.lines[index - 1].up
					? p.point(value.x, value.y)
					: p.line(this.state.lines[index - 1].x || 0, this.state.lines[index - 1].y || 0, value.x, value.y);
			});
		};
	};




	componentDidMount() {
		this.myP5 = new p5(this.Sketch, this.myRef.current);
	}

	clear() {
		this.questionDoc.set({ lines: [] });
	}
	render() {
		return (
			<div className="canvas-wrap">
				<div ref={this.myRef} className="canvas" style={{backgroundImage: `url('${this.props.backgroundImage || ''}')`}}  />
	
			</div>
		);
	}
}
