import React from 'react';
import logo from './logo.svg';
import './App.css';
import firebase from 'firebase';
import { Questions } from './questions';
import { Configure } from './configure';
import { ViewResults } from './view-results';
import {Leaderboard } from './leaderboard'
class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedQ: null
		};
		this.selectQuestion = this.selectQuestion.bind(this);
	}

	selectQuestion(id) {
		console.log(id);
		this.setState({
			selectedQ: id
		});
	}

	render() {
		return (
			<div className="overview">
				<div className="questions">
					<Questions onSelectQuestion={this.selectQuestion} />
				</div>
				<div className="configure">
					<Configure id={this.state.selectedQ} />
				</div>
        <div className="leaderboard">
					<Leaderboard />
				</div>
				{this.state.selectedQ ? <div className="view-results"><ViewResults id={this.state.selectedQ}></ViewResults></div> : ''}
			</div>
		);
	}
}

const config = {
	apiKey: 'AIzaSyA6YhdHzV7DShRciiu3w1XQyR3D6QvA0ek',
	authDomain: 'zooquiz-5c5e4.firebaseapp.com',
	databaseURL: 'https://zooquiz-5c5e4.firebaseio.com',
	projectId: 'zooquiz-5c5e4',
	storageBucket: 'zooquiz-5c5e4.appspot.com',
	messagingSenderId: '969942667631',
	appId: '1:969942667631:web:fa35ce94b6493559c8189a'
};
firebase.initializeApp(config);

export default App;
